import(/* webpackMode: "eager" */ "/home/deline/components/molecules/events.tsx");
;
import(/* webpackMode: "eager" */ "/home/deline/components/molecules/jobs.tsx");
;
import(/* webpackMode: "eager" */ "/home/deline/components/molecules/news.tsx");
;
import(/* webpackMode: "eager" */ "/home/deline/components/molecules/slideshow.tsx");
;
import(/* webpackMode: "eager" */ "/home/deline/components/molecules/videos.tsx");
;
import(/* webpackMode: "eager" */ "/home/deline/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/home/deline/node_modules/@mui/material/Grid/index.js");
;
import(/* webpackMode: "eager" */ "/home/deline/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/deline/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
